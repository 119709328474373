<template>
    <div style="background: #f5f5f5; min-height: 100vh;height: auto;">
        <div class="header">
            <van-nav-bar :title="$t('C2CWithdrawalList')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div style="padding-top: 55px;">
            <van-list v-model="loading" :finished="finished" :finished-text="$t('common.nomore')"
                :loading-text="$t('jia-zai-zhong')" @load="onLoad">
                <div v-for="item in list" :key="item.id" @click="onItemClick(item)">
                    <van-swipe-cell>
                        <!-- 0=待审核,1=已通过,2=已拒绝 -->
                        <div class="cardV">
                            <div class="itemV1">
                                <span class="itemTv1">{{ item.price }}</span>
                                <span v-if="item.status == 0" class="itemTv5">{{
                                    $t("WaitingReview")
                                }}</span>
                                <span v-if="item.status == 1" class="itemTv5">{{
                                    $t("Approved")
                                }}</span>
                                <span v-if="item.status == 2" class="itemTv5">{{
                                    $t("Refuse")
                                }}</span>
                            </div>

                            <div class="itemV2">
                                <span class="itemTv3">{{ item.createtime | dateformat }}</span>
                                <span class="itemTv4">{{ item.order_sn }}</span>
                            </div>
                        </div>
                    </van-swipe-cell>
                </div>
            </van-list>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            page: 0,
        };
    },
    created() { },
    methods: {
        async onLoad() {
            this.page++;
            const { data } = await this.$http.get(
                "/home/user/withdrawCList?page=" + this.page
            );
            if (data) {
                this.loading = false;
                if (data.code === 200) {
                    if (data.data.list.length == 0) {
                        this.finished = true;
                    }
                    if (this.page == 1) {
                        this.list = data.data.list;
                    } else {
                        this.list.push(...data.data.list);
                    }
                }
            }
        },
        onItemClick(item) {
            this.$router.push({ path: "/assets/C2CWithdrawalInfo/" + item.id });
        },
    },
};
</script>
<style lang="less" scoped>
.cardV {
    height: 4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 0.1px solid #f2f3f5;
}

.itemV1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itemV2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.itemTv1 {
    color: #333;
    font-size: 15px;
}

.itemTv2 {
    font-weight: 500;
    color: #e74b71;
    font-size: 15px;
}

.itemTv3 {
    color: #969799;
    font-size: 10px;
}

.itemTv4 {
    color: #969799;
    font-size: 10px;
}

.itemTv5 {
    font-weight: 500;
    color: #333;
    font-size: 15px;
}

.addTv {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 3.18rem;
    font-family: "Alibaba PuHuiTi";
    font-style: normal;
    font-weight: 700;
    position: fixed;
    right: 1rem;
    top: 60%;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
  